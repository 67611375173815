import React from 'react'
import "./Navbar.css"
import { useState } from 'react';
import Logo from "./IQLOGO.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    return (
        <>
            <nav className='container'>
                <div className="logo">
                    <a href="/">
                        <img src={Logo} alt="Logo" />
                    </a>
                </div>
                <ul className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
                    <li><a href="/">Home</a></li>
                    <li><a href="/aboutus">AboutUs</a></li>

                    <li><a href="/course">Courses</a></li>
                    <li><a href="/">Mock Interviews</a></li>
                    <li><a href="/mentor">Mentors</a></li>
                    <li><a href="/testimonials">Testimonial</a></li>
                    <li><a href="/contactus">Contact Us</a></li>
                    <li className='joincommunity'><a href="https://chat.whatsapp.com/FwraR5rxyioFWkMctPxImb" target='blank'>join community for updates</a></li>
                </ul>
                <div className="login-button">
                    <a href="https://chat.whatsapp.com/FwraR5rxyioFWkMctPxImb" target='blank'>join community for updates</a>
                </div>
                <div className="menu-toggle" onClick={handleMenuToggle}>
                    <FontAwesomeIcon icon={faBars} />
                </div>
            </nav>

        </>
    )
}

export default Navbar
