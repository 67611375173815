import React from "react";
import "./Placement.css";
import Heading from "../Common/Heading/Heading";

import { useNavigate } from "react-router-dom";
// import InterviewData from "../../Utils/Card/mockinterviewcards";
// import CouseData from "../../Utils/Card/coursecards";
// import InternshipData from "../../Utils/Card/internshipcard";
import { useState, useCallback, useEffect } from "react";
import { fetchCourseRepos, fetchInternshipRepos, fetchMockInterviewRepos } from "../../Utils/cardutil";
const PlacementPreparation = () => {
  const [courserepos, setCourseRepos] = useState([]);
  const [MockInterviewRepos, setMockInterviewRepos] = useState([]);
  const [internshiprepos, setInternshipRepos] = useState([]);
  const navigate = useNavigate();
  const fetchAndSetData = useCallback(async () => {
    const coursedata = await fetchCourseRepos();
    if (!coursedata.isErr) {
      setCourseRepos(coursedata.finddata);
    }
    const MockInterviewdata = await fetchMockInterviewRepos();
    if (!MockInterviewdata.isErr) {
      setMockInterviewRepos(MockInterviewdata.finddata);
    }
    const InternshipData = await fetchInternshipRepos();
    if (!InternshipData.isErr) {
      setInternshipRepos(InternshipData.finddata);
    }
  }, []);
  useEffect(() => {
    fetchAndSetData();
  }, [fetchAndSetData]);

  return (
    <>
      <Heading
        subtitle="Our Services"
        title="Boost your placement preparation, explore top courses, and get internship opportunities."
      />
      <section className="placement-preparation">
        <h2>Mock Interviews</h2>
        <p>
          Improve your resume with expert tips, practice mock technical
          interviews, and get ready for HR interviews with personalized
          guidance.
          <b>
            Interviews are scheduled to occur within one week after
            registration.
          </b>
        </p>
        <div className="card-container">
          {MockInterviewRepos.map((item) => {
            console.log(item.image);
            return (
              <div className="card" key={item.id}>
                <img
                  src={require(`${item.image}`)}
                  alt="mock interview related"
                />
                <h3>{item.topicName}</h3>
                <p className="description">
                  {item.description.map((item) => (
                    <li>{item}</li>
                  ))}
                </p>
                <div className="topicPriceAndButton">
                  <div className="price">
                    <span style={{ textDecorationLine: "line-through" }}>
                      {" "}
                      1999₹
                    </span>{" "}
                    <span className="discounted">{item.price}₹</span>
                  </div>
                  <button
                    className="buy-now"
                    onClick={() => {
                      navigate(`/moduleCard/${item.slug_name}`);
                    }}
                  >
                    Register Now
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <section className="placement-preparation">
        <h2>Courses Offered </h2>
        <p>
          Learn ML with Python, master data analysis with Python, and explore
          more courses to gain essential skills.
        </p>
        <div className="card-container">
          {courserepos.map((item) => {
            console.log(item.image);
            return (
              <div className="card" key={item.id}>
                <img
                  src={require(`${item.image}`)}
                  alt="mock interview related"
                />
                <h3>{item.topicName}</h3>
                <p className="description">
                  {item.description.map((item) => (
                    <li>{item}</li>
                  ))}
                </p>
                <div className="topicPriceAndButton">
                  <div className="price">
                    <span style={{ textDecorationLine: "line-through" }}>
                      {" "}
                      1999₹
                    </span>{" "}
                    <span className="discounted">{item.price}₹</span>
                  </div>
                  <button
                    className="buy-now"
                    onClick={() => {
                      navigate(`/moduleCard/${item.slug_name}`);
                    }}
                  >
                    {" "}
                    Enroll now
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <section className="placement-preparation">
        <h2>Internship Opportunity</h2>
        <p>
          Gain hands-on experience, work on real-world projects, and enhance
          your resume with IQPaths internships open now .
        </p>
        <div className="card-container">
          {internshiprepos.map((item) => {
            return (
              <div className="card" key={item.id}>
                <img
                  src={require(`${item.image}`)}
                  alt="Full Resume Correction"
                />
                <h3>{item.topicName}</h3>
                <p className="description">{item.description}</p>
                <button
                  className="buy-now"
                  onClick={() => {
                    navigate(`/moduleCard/${item.slug_name}`);
                  }}
                >
                  Apply Now
                </button>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default PlacementPreparation;
