import React from "react";
import styles from "./Thankyou.module.css"; // Import the CSS module

const Thankyou = ({  product }) => {
  return (
    <div className={styles.thankYouCard}>
      <h2>Thank you for your purchase!</h2>
      <p className={styles.message}>
        Dear Sir/Mam,<br/>
        Thank you for purchasing {product} from
        IQPaths! We are thrilled to have you on board and appreciate your trust
        in us. Our IQPaths team will contact you regarding the service within
        the next 24 hours to ensure you have all the information you need. 
        <br/><br/>If
        you have opted for a service related to placements or internships,
        please have your resume/CV ready as our HR team will reach out to you as
        soon as possible. For any queries or further assistance, feel free to
        email us at info@iqpaths.com We look forward to helping you achieve your
        career goals!<br/> Best regards,<br/> IQPaths Team
      </p>
    </div>
  );
};

export default Thankyou;
