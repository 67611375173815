import React from "react";
// import { FaFacebookF, FaGithub, FaLinkedinIn } from 'react-icons/fa';
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <>
      <div className="profile-card">
        <div className="profile-details">
          <h1>What we do? </h1>
          <p>
          <div>
  <h2>Welcome to IQ Paths</h2>
  
  <p>IQ Paths is your essential resource for navigating the transition from academia to professional life. We specialize in providing comprehensive support and tools to help students and job seekers excel in today’s competitive job market.</p>
  
  <h3>Interview Preparation Excellence</h3>
  <p>Gain a competitive edge with our tailored mock technical and HR interview sessions conducted by real corporate professionals. Receive invaluable feedback, insights, and tips to enhance your interview performance and boost your confidence.</p>
  
  <h3>Stay Ahead with Industry Insights</h3>
  <p>Stay informed about the latest advancements in Artificial Intelligence (AI), Machine Learning (ML), and Data Analytics. Our updates and resources ensure you are always up-to-date with industry trends and innovations.</p>
  
  <h3>Personalized Guidance and Support</h3>
  <p>Receive personalized guidance throughout your placement journey. Our experts provide valuable resources, one-on-one mentoring, and expert advice to help you navigate the complexities of the job market and make informed career decisions.</p>
  
  <h3>Exclusive Job and Internship Opportunities</h3>
  <p>Access exclusive job and internship opportunities with leading companies across various industries. We connect you directly with potential employers, helping you find the right opportunities to kickstart your career.</p>
  
  <h3>Comprehensive Skill Development</h3>
  <p>Build and refine essential skills necessary for professional success. From technical skills to soft skills, our platform offers resources and training to help you develop a well-rounded skill set that employers value.</p>
  
  <h3>Community and Networking</h3>
  <p>Join a vibrant community of like-minded individuals and industry professionals. Network, collaborate, and learn from peers and mentors who share your passion for continuous learning and career advancement.</p>
  
  <h3>Empowerment and Future Readiness</h3>
  <p>Empower yourself with the knowledge and skills needed to thrive in the dynamic corporate environment. IQ Paths equips you with the tools and confidence to make a successful transition from campus to corporate life.</p>
  
  <p>Take the first step towards a brighter future with IQ Paths. Enhance your knowledge, build your skills, and prepare for a successful career journey.</p>
</div>

          </p>
          {/* <div className="profile-social">
            {/* <a href="facebook-url" target="_blank" rel="noopener noreferrer">
              <img src="" alt="Facebook" />
            </a>
            <a href="github-url" target="_blank" rel="noopener noreferrer">
              <img src="" alt="GitHub" />
            </a>
            <a href="linkedin-url" target="_blank" rel="noopener noreferrer">
              <img src="" alt="LinkedIn" />
            </a> */}
          {/* </div>
        </div> */}
        </div>
        {/* <div className="profile-image">
          <img src={ceo} alt="Sanket Gupta" />
        </div> */}
      </div>
    </>
  );
};

export default AboutUs;
