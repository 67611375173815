// import React, { useEffect, useState } from 'react'
import classes from "./modulepage.module.css";

import image from "./banner.jpeg";
import { useParams } from "react-router-dom";
import { useEffect, useState,useCallback } from "react";
import PaymentForm from "../PaymentForm/PaymentForm";
import { createOrder, fetchDescripton, verifyPayment } from "../../Utils/cardutil";
import { getRazorPay, loadRazorPay } from "../../Utils/Razorpay";
import Spinner from "../../Compoments/Spinner/Spinner";
import Modal from "../../Compoments/Common/UI/Modal";
import Thankyou from "./component/Thankyou";
function ModulePage() {
  const { slugname } = useParams();
  const [IsModalOpen, setModalOpen] = useState(false);
  const [razorOpts, setRazorOpts] = useState(null);
  const [orderToken, setOrderToken] = useState(null);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  
  const[isInternsship,setIsInternship]=useState(false);

  const [getdata,setgetdata]=useState(null);
  const fetchAndSetData = useCallback(async () => {
    const data = await fetchDescripton(slugname);
    if (!data.isErr) {
      setgetdata(data.data);
      console.log(data.data);
      if(data.data.slug_name==="web-development-internship"||data.data.slug_name==="ai-ml-developer-internship"||data.data.slug_name==="human-resource-internship"){
        setIsInternship(true);
      }
    }
    else{
      alert('problem in fetching data at this moment');
    }
  }, []);
  useEffect(() => {
    loadRazorPay();
  }, []);
  useEffect(() => {
    fetchAndSetData();
  }, [fetchAndSetData]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePaymentSuccess = useCallback(() => {
    setIsPaymentProcessing(false);
    setIsSuccessModalOpen(true);
  }, []);

  const closeLoadingModal = useCallback(() => {
    setIsPaymentProcessing(false);
  }, []);

  const closeSuccessModal = useCallback(() => {
    setIsSuccessModalOpen(false);
  }, []);

  const handlePayNow = useCallback(
    (user) => {
      setModalOpen(false);
      setIsPaymentProcessing(true);
      const rzp = getRazorPay({
        ...razorOpts,
        prefill: { contact: `+91${user.contactNumber}` },
        notes: { ...user,id: slugname },
        theme: {
          color: "#0047B2",
        },
        handler: async ({
          razorpay_order_id,
          razorpay_payment_id,
          razorpay_signature,
        }) => {
          verifyPayment(
            {
              ...user,
              token: orderToken,
              orderId: razorpay_order_id,
              paymentId: razorpay_payment_id,
              signature: razorpay_signature,
            },
            handlePaymentSuccess,
          );
        },
      });
      rzp.on("payment.failed", (response) => {
        console.log("Payment Failed:", response);
      });
      rzp.open();
    },
    [razorOpts, slugname, orderToken, handlePaymentSuccess],
  );

  const closePaymentModal = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);


  const handlePurchase = useCallback(
    async () => {
      setModalOpen(true);

      const { token, order_id, amount, currency, key, name, description } =
      await createOrder(slugname);
      setOrderToken(token);

      setRazorOpts({ order_id, amount, currency, key, name, description });
    },[slugname]
  );
  return (
    // <div className='container'>
    <div className={classes.modulepage}>
      {getdata? (
        <>
          <div className={classes.moduleImage}>
            <img src={image} alt="banner" />
          </div>
          <div className={classes.detail}>
            <div className={classes.topicName}>{getdata.topic_name}</div>
            <div className="hero-buttons">
              {!isInternsship?<button onClick={handlePurchase} className={classes.btnPrimary}>
                Buy Now
              </button>:<></>}
            </div>
            <div className={classes.description}>
              <h2>Description</h2>
              <p> {getdata.description}</p>
            </div>
            <div className={classes.knowmore}>
              <h2>Things To Know More </h2>
              <p>
                <b>Amount in Rs: {getdata.know_more.price} </b>
              </p>
              <p>
                <b>Topics</b>
              </p>
              <p>
                {getdata.know_more.things_covered.map((item) => (
                  <li>{item}</li>
                ))}
              </p>
              <p>
                <b>Message :</b>
                {getdata.message}
              </p>
              <p>
                <b>contact mail :</b>
                {getdata.know_more.contact_mail}
              </p>
              <p>
                <b>Open Now : {getdata.live_now}</b>
              </p>
            </div>
            <p>
              <b>Note: </b>If you have paid and didnt recieve email from us with
              in 1hr then you can just mail us at our mail or reach out through
              contact details
            </p>
            {IsModalOpen ? <PaymentForm handlePayNow={handlePayNow}
            closeModal={closePaymentModal} /> : <></>}
            {isPaymentProcessing ? (
          <div className={`${classes.loadingModalWrapper}`}>
            <div className={`${classes.loadingModal}`}>
              <Spinner size={50} />
            </div>
            <div className={`${classes.overlay}`} onClick={closeLoadingModal} />
          </div>
        ) : (
          ""
        )}

        {isSuccessModalOpen ? (
          <Modal hideCard={closeSuccessModal} >
            <div className={classes.modalclass}>
            <Thankyou/>
            <button  className ={classes.btnPrimary} onClick={closeSuccessModal}> Back to page </button>
            </div>
          </Modal>
        ) : (
          ""
        )}
          </div>
        </>
      ) : (
        <div style={{ marginTop: 400 }}> data is loading..</div>
      )}
    </div>
    // </div>
  );
}

export default ModulePage;
